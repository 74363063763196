import { combineSlices, configureStore } from "@reduxjs/toolkit";
import { createStore, applyMiddleware, combineReducers } from "redux";
import { thunk } from "redux-thunk";
import authSliceReducer from "./auth";
import investorsSliceReducer from "./investors";
import firmsSliceReducer from "./firms";
import investorsWithFirmsSliceReducer from "./investorsWithFirms";
import userSliceReducer from "./users";
import companiesSliceReducer from "./companies";
import introductionsSliceReducer from "./introductions";
const rootReducer = combineReducers({
  auth: authSliceReducer,
  investors: investorsSliceReducer,
  firms: firmsSliceReducer,
  investorsWithFirms: investorsWithFirmsSliceReducer,
  users: userSliceReducer,
  companies: companiesSliceReducer,
  introductions: introductionsSliceReducer,
});
// const store = createStore(rootReducer, applyMiddleware(thunk));
const store = configureStore({
  reducer: rootReducer,
  middleware: () => [thunk],
});
export default store;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate, useRoutes } from "react-router-dom";

import { getInvestors } from "./store/investors";
import { setAuthData } from "./store/auth";

import Dashboard from "./pages/Dashboard/Dashboard";
import Login from "./pages/Login/Login copy";
import MailMerge from "./pages/MailMerge/MailMerge";
import DashboardType from "./pages/Dashboard/DashboardType/DashboardType";
import { getFirms } from "./store/firms";
import { getInvestorsWithFirms } from "./store/investorsWithFirms";
import Admin from "./pages/Admin/Admin";
import { getUsers } from "./store/users";
import { getCompanies } from "./store/companies";
import { getIntroductions } from "./store/introductions";

const Layout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useSelector((state) => state.auth);
  const nav = [
    {
      path: "/dashboard",
      element: (
        <Dashboard>
          <Outlet />
        </Dashboard>
      ),
      children: [
        {
          path: "admin/:type",
          element: (
            <ProtectedRoute
              {...{ auth: auth, requiredType: "Admin", navigate }}
            >
              <Admin />
            </ProtectedRoute>
          ),
        },
        {
          path: "type/mail-merge",
          element: <MailMerge />,
        },
        {
          path: "type/:type",
          element: <DashboardType />,
        },
      ],
    },
  ];
  const routes = [
    {
      path: "",
      element: <Login />,
    },
    ...nav,
    {
      path: "/*",
      element: "error",
    },
  ];
  useEffect(() => {
    if (auth?.token) {
      dispatch(getInvestorsWithFirms());
      dispatch(getInvestors());
      if (auth.type === "Admin") {
        dispatch(getFirms());
        dispatch(getUsers());
        dispatch(getCompanies());
        dispatch(getIntroductions());
      }
      if (!location.pathname.slice(1)) {
        navigate("/dashboard/type/investor");
      }
    } else {
      let localToken = JSON.parse(localStorage.getItem("appdata"));
      if (localToken?.token) {
        dispatch(setAuthData(localToken));
      } else {
        localStorage.setItem("apptoken", "");
        navigate("/");
      }
    }
  }, [auth?.token]);

  return <div className="layout">{useRoutes(routes)}</div>;
};

export default Layout;
const ProtectedRoute = ({ auth, requiredType, navigate, children }) => {
  // If user type does not match the required type, redirect to error page
  if (auth?.type !== requiredType) {
    navigate("/dashboard/type/investor");
    return <></>;
  }

  // If the user is authorized, render the children components
  return children;
};

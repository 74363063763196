import React from "react";

const Table = ({ header = [], data = [] }) => {
  return (
    <div
      className="cust_table"
      style={{
        "--count": header?.length || 1,
      }}
    >
      {
        <div className="table_header">
          {header.map(({ title }, i) => (
            <div key={i} className={"col" + (i + 1)}>
              {title}
            </div>
          ))}
        </div>
      }
      {
        <div className="table_body">
          {data?.length === 0 && (
            <div className="no_data">No data available</div>
          )}
          {data.map((el, i) => (
            <div className="table_row">
              {header.map(({ id }, i) => (
                <div key={i} className={"col" + (i + 1)}>
                  {el?.[id] || "-"}
                </div>
              ))}
            </div>
          ))}
        </div>
      }
    </div>
  );
};

export default Table;

import React, { useCallback, useState } from "react";
import Bold from "@tiptap/extension-bold";
import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import { EditorContent, useEditor } from "@tiptap/react";
import Code from "@tiptap/extension-code";
import Italic from "@tiptap/extension-italic";
import Link from "@tiptap/extension-link";
import Highlight from "@tiptap/extension-highlight";
import Strike from "@tiptap/extension-strike";
import Underline from "@tiptap/extension-underline";
import TextStyle from "@tiptap/extension-text-style";
import Color from "@tiptap/extension-color";
import ListKeymap from "@tiptap/extension-list-keymap";
import BulletList from "@tiptap/extension-bullet-list";
import ListItem from "@tiptap/extension-list-item";
import Placeholder from "@tiptap/extension-placeholder";
import { Mark, Node } from "@tiptap/core";
// Bold.configure({
//   HTMLAttributes: {
//     class: "custom_bold",
//   },
// });
// Create a custom mark for dynamic variables
// Create a custom node for dynamic variables
const DynamicVariable = Node.create({
  name: "dynamicVariable",

  group: "inline",
  inline: true,
  atom: true,

  addAttributes() {
    return {
      variableName: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-variable-name"),
        renderHTML: (attributes) => {
          return { "data-variable-name": attributes.variableName };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "button[data-dynamic-variable]",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "button",
      {
        "data-dynamic-variable": "",
        "data-placeholder": `--${HTMLAttributes.variableName}`, // Placeholder attribute with variable name
        class: "dynamic-variable-button",
        contenteditable: "false", // Make button non-editable
        ...HTMLAttributes,
      },
      // `--${HTMLAttributes.variableName}`, // Text inside the button
      "",
    ];
  },
});

const data_map = [
  {
    user_name: "user1",
    user_designation: "userdesignation1",
    user_role: "userrole1",
  },
  {
    user_name: "user2",
    user_designation: "userdesignation2",
    user_role: "userrole2",
  },
  {
    user_name: "user3",
    user_designation: "userdesignation3",
    user_role: "userrole3",
  },
];

const RTE = ({ formatting = false }) => {
  const [suggestions, setSuggestions] = useState([]);
  const editor = useEditor({
    extensions: [
      Placeholder.configure({
        // Use a placeholder:
        placeholder: "Write something …",
        // Use different placeholders depending on the node type:
        // placeholder: ({ node }) => {
        //   if (node.type.name === 'heading') {
        //     return 'What’s the title?'
        //   }

        //   return 'Can you add some further context?'
        // },
      }),
      DynamicVariable,
      Document,
      Text,
      Paragraph,
      ...(formatting
        ? [
            Bold.configure({
              HTMLAttributes: {
                class: "custom_bold",
              },
            }),
            Code,
            Strike,
            Italic,
            Link.configure({
              openOnClick: true,
              autolink: false,
              defaultProtocol: "https",
              linkOnPaste: false,
            }),
            Highlight.configure({ multicolor: true }),
            Underline,
            TextStyle,
            Color,
            BulletList,
            ListItem,
            ListKeymap,
          ]
        : []),
    ],
    // content: `
    //     <p>This isn’t bold.</p>
    //     <p><strong>This is bold.</strong></p>
    //     <p><b>And this.</b></p>
    //     <p>This as well.</p>
    //     <p>Oh, and this!</p>
    //     <p>Cool, isn’t it!?</p>
    //     <p>Up to font weight 999!!!</p>`,
    onUpdate: ({ editor }) => {
      const text = editor.getText().slice(-1);
      console.log(text);

      if (text == "@") {
        setSuggestions(["user_name", "user_designation", "user_role"]);
      } else {
        setSuggestions([]);
      }
    },
  });
  const handleSuggestionClick = (suggestion) => {
    // const text = editor.getText();
    // const pos = text.lastIndexOf("@");
    const { state } = editor;
    const { doc } = state;
    // Get the position of the last character
    const lastPos = doc.content.size;
    // const lastPos = pos;
    // console.log(doc.content, lastPos, pos);

    if (lastPos > 0) {
      // Chain actions: delete the last character and insert the dynamic variable button
      editor
        .chain()
        .focus()
        .deleteRange({ from: lastPos - 2, to: lastPos }) // Delete the last character
        // .deleteCurrentNode()
        .insertContent([
          {
            type: "dynamicVariable", // Insert the dynamic variable button
            attrs: { variableName: suggestion },
          },
          {
            type: "text",
            text: " ", // Add a space after the button to allow typing
          },
        ])
        .run();
    }

    setSuggestions([]);
  };
  const setLink = useCallback(() => {
    const previousUrl = editor.getAttributes("link").href;
    const url = window.prompt("URL", previousUrl);

    // cancelled
    if (url === null) {
      return;
    }

    // empty
    if (url === "") {
      editor.chain().focus().extendMarkRange("link").unsetLink().run();

      return;
    }

    // update link
    editor.chain().focus().extendMarkRange("link").setLink({ href: url }).run();
  }, [editor]);

  if (!editor) {
    return null;
  }

  return (
    <div className="rich_text_editor">
      {formatting ? (
        <div className="control-group">
          <div className="button-group">
            <button
              onClick={() => editor.chain().focus().toggleBold().run()}
              className={editor.isActive("bold") ? "is-active" : ""}
            >
              B
            </button>
            <button
              onClick={() => editor.chain().focus().toggleItalic().run()}
              className={editor.isActive("italic") ? "is-active" : ""}
            >
              I
            </button>
            <button
              onClick={() => editor.chain().focus().toggleCode().run()}
              className={editor.isActive("code") ? "is-active" : ""}
            >
              Toggle code
            </button>
            {!editor.isActive("link") ? (
              <button
                onClick={setLink}
                className={editor.isActive("link") ? "is-active" : ""}
              >
                Set link
              </button>
            ) : (
              <button
                onClick={() => editor.chain().focus().unsetLink().run()}
                disabled={!editor.isActive("link")}
              >
                Unset link
              </button>
            )}
            <button
              onClick={() => editor.chain().focus().toggleStrike().run()}
              className={editor.isActive("strike") ? "is-active" : ""}
            >
              Toggle strike
            </button>
            <button
              onClick={() => editor.chain().focus().toggleUnderline().run()}
              className={editor.isActive("underline") ? "is-active" : ""}
            >
              Toggle underline
            </button>
            <button
              onClick={() => editor.chain().focus().toggleBulletList().run()}
              className={editor.isActive("bulletList") ? "is-active" : ""}
            >
              Toggle bullet list
            </button>
            <button
              onClick={() =>
                editor.chain().focus().splitListItem("listItem").run()
              }
              disabled={!editor.can().splitListItem("listItem")}
            >
              Split list item
            </button>
            <button
              onClick={() =>
                editor.chain().focus().sinkListItem("listItem").run()
              }
              disabled={!editor.can().sinkListItem("listItem")}
            >
              Sink list item
            </button>
            <button
              onClick={() =>
                editor.chain().focus().liftListItem("listItem").run()
              }
              disabled={!editor.can().liftListItem("listItem")}
            >
              Lift list item
            </button>
            <button
              onClick={() => editor.chain().focus().toggleHighlight().run()}
              className={editor.isActive("highlight") ? "is-active" : ""}
            >
              Toggle highlight
            </button>
            <button
              onClick={() =>
                editor
                  .chain()
                  .focus()
                  .toggleHighlight({ color: "#ffc078" })
                  .run()
              }
              className={
                editor.isActive("highlight", { color: "#ffc078" })
                  ? "is-active"
                  : ""
              }
            >
              Orange
            </button>
            <button
              onClick={() =>
                editor
                  .chain()
                  .focus()
                  .toggleHighlight({ color: "#8ce99a" })
                  .run()
              }
              className={
                editor.isActive("highlight", { color: "#8ce99a" })
                  ? "is-active"
                  : ""
              }
            >
              Green
            </button>
            <button
              onClick={() =>
                editor
                  .chain()
                  .focus()
                  .toggleHighlight({ color: "#74c0fc" })
                  .run()
              }
              className={
                editor.isActive("highlight", { color: "#74c0fc" })
                  ? "is-active"
                  : ""
              }
            >
              Blue
            </button>
            <button
              onClick={() =>
                editor
                  .chain()
                  .focus()
                  .toggleHighlight({ color: "#b197fc" })
                  .run()
              }
              className={
                editor.isActive("highlight", { color: "#b197fc" })
                  ? "is-active"
                  : ""
              }
            >
              Purple
            </button>
            <button
              onClick={() =>
                editor.chain().focus().toggleHighlight({ color: "red" }).run()
              }
              className={
                editor.isActive("highlight", { color: "red" })
                  ? "is-active"
                  : ""
              }
            >
              Red ('red')
            </button>
            <button
              onClick={() =>
                editor
                  .chain()
                  .focus()
                  .toggleHighlight({ color: "#ffa8a8" })
                  .run()
              }
              className={
                editor.isActive("highlight", { color: "#ffa8a8" })
                  ? "is-active"
                  : ""
              }
            >
              Red (#ffa8a8)
            </button>
            <button
              onClick={() => editor.chain().focus().unsetHighlight().run()}
              disabled={!editor.isActive("highlight")}
            >
              Unset highlight
            </button>
          </div>
          <div className="button-group">
            <input
              type="color"
              onInput={(event) =>
                editor.chain().focus().setColor(event.target.value).run()
              }
              value={editor.getAttributes("textStyle").color}
              data-testid="setColor"
            />
            <button
              onClick={() => editor.chain().focus().setColor("#958DF1").run()}
              className={
                editor.isActive("textStyle", { color: "#958DF1" })
                  ? "is-active"
                  : ""
              }
              data-testid="setPurple"
            >
              Purple
            </button>
            <button
              onClick={() => editor.chain().focus().setColor("#F98181").run()}
              className={
                editor.isActive("textStyle", { color: "#F98181" })
                  ? "is-active"
                  : ""
              }
              data-testid="setRed"
            >
              Red
            </button>
            <button
              onClick={() => editor.chain().focus().setColor("#FBBC88").run()}
              className={
                editor.isActive("textStyle", { color: "#FBBC88" })
                  ? "is-active"
                  : ""
              }
              data-testid="setOrange"
            >
              Orange
            </button>
            <button
              onClick={() => editor.chain().focus().setColor("#FAF594").run()}
              className={
                editor.isActive("textStyle", { color: "#FAF594" })
                  ? "is-active"
                  : ""
              }
              data-testid="setYellow"
            >
              Yellow
            </button>
            <button
              onClick={() => editor.chain().focus().setColor("#70CFF8").run()}
              className={
                editor.isActive("textStyle", { color: "#70CFF8" })
                  ? "is-active"
                  : ""
              }
              data-testid="setBlue"
            >
              Blue
            </button>
            <button
              onClick={() => editor.chain().focus().setColor("#94FADB").run()}
              className={
                editor.isActive("textStyle", { color: "#94FADB" })
                  ? "is-active"
                  : ""
              }
              data-testid="setTeal"
            >
              Teal
            </button>
            <button
              onClick={() => editor.chain().focus().setColor("#B9F18D").run()}
              className={
                editor.isActive("textStyle", { color: "#B9F18D" })
                  ? "is-active"
                  : ""
              }
              data-testid="setGreen"
            >
              Green
            </button>
            <button
              onClick={() => editor.chain().focus().unsetColor().run()}
              data-testid="unsetColor"
            >
              Unset color
            </button>
          </div>
        </div>
      ) : (
        ""
      )}
      <EditorContent editor={editor} />
      <hr />
      {suggestions.length > 0 && (
        <div className="suggestions">
          {suggestions.map((suggestion, index) => (
            <div key={index} onClick={() => handleSuggestionClick(suggestion)}>
              {suggestion}
            </div>
          ))}
        </div>
      )}
      <hr />
      {data_map.map((el, i) => (
        <div
          key={i}
          className="to_show"
          dangerouslySetInnerHTML={{
            __html: (() => {
              let temp = editor.getHTML();

              Object.keys(el).map(
                (el1) => (temp = temp.replaceAll(el1, el[el1]))
              );
              return temp;
            })(),
          }}
        ></div>
      ))}
      {JSON.stringify(editor.getHTML())}
    </div>
  );
};

export default RTE;

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import GlideTable from "../../component/GlideTable/GlideTable";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../component/Table/Table";
import SearchAndFilter from "../../component/SearchAndFilter/SearchAndFilter";
import { filters } from "../../utils/data";
import CustForm from "../../component/Form/CustForm";
import { options } from "marked";
import axios from "axios";
import { getInvestors } from "../../store/investors";
import { getInvestorsWithFirms } from "../../store/investorsWithFirms";
import { getFirms } from "../../store/firms";
import { getUsers } from "../../store/users";
import { getCompanies } from "../../store/companies";
import {
  emailIntroduction,
  getIntroductions,
  markIntroduction,
} from "../../store/introductions";

const default_investor = {
  photo: {
    name: "",
    base64: "",
  },
  // "https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png",
  // "",
  name: "Punitesh Poojary",
  email: "ppunitesh@gmail.com",
  stage: ["Buyout", "Growth Stage", "Mid market"],
  firmId: "66d5aa0a4249eb6d50035cd8",
  designation: "asddesign",
  sector: ["Agritech"],
  // type: "Investor",
  cities: ["Dubai", "Dhahran", "San Mateo"],
  countries: ["India", "alsdn ", "Singapore"],
  linkedIn: "somelinkedin link",
  pastInvestments: ["Arohi", "American Century"],
  locationCities: ["Dubai", "Dhahran", "San Mateo"],
  locationCountries: ["India", "alsdn ", "Singapore"],
};
const initialInvestorData = {
  photo: {
    base64: "",
    name: "",
  },
  name: "",
  email: "",
  stage: [],
  firmId: "",
  designation: "",
  sector: [],
  type: "",
  cities: [],
  countries: [],
  linkedIn: "",
  pastInvestments: [],
  // ...default_investor,
};
const initialFirmData = {
  firmName: "",
  website: "",
  logo: {
    base64: "",
    name: "",
  },
};
const initialuserData = {
  email: "",
  name: "",
  type: "",
  photo: {
    base64: "",
    name: "",
  },
  comapanyIdL: "",
  linkedIn: "",
};
const initialCompaniesData = {
  location: "", // required
  website: "", // required
  investmentManagerId: "", // required
  blurb: "", // required
  stage: "", // required
};
const initialIntroduction = {
  investorsName: "", // required
  investorsEmail: "", // required
  investorsFirm: "", // required

  companyName: "", // required
  founderName: "", // required
  founderEmail: "", // required
};
const Admin = () => {
  const dispatch = useDispatch();
  const { type } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const investors = useSelector((state) => state.investors);
  const firms = useSelector((state) => state.firms);
  const users = useSelector((state) => state.users);
  const companies = useSelector((state) => state.companies);
  const introductions = useSelector((state) => state.introductions);

  const [search, setsearch] = useState("");
  const [showing_filters, setshowing_filters] = useState([]);
  const [current_filters, setcurrent_filters] = useState({});

  const [investorsData, setInvestorsData] = useState({
    ...initialInvestorData,
  });
  const [firmData, setFirmData] = useState({
    ...initialFirmData,
  });
  const [userData, setuserData] = useState({
    ...initialuserData,
  });
  const [companyData, setcompanyData] = useState({
    ...initialCompaniesData,
  });
  const [introductionData, setintroductionData] = useState({
    ...initialIntroduction,
  });

  const [modal, setmodal] = useState({
    type: "",
    is_saving: false,
  });
  const [firm_wrt_id, setfirm_wrt_id] = useState({});
  const [to_delete, setTo_delete] = useState({
    // id: "",
    // name:"",
    // logo/photo :""
  });
  // console.log(firms);

  const [table_header, settable_header] = useState([]);
  const [table_data, settable_data] = useState([]);
  useEffect(() => {
    setfirm_wrt_id(
      firms.data.reduce((acc, curr) => ({ ...acc, [curr._id]: curr }), {})
    );
  }, [firms]);
  useEffect(() => {
    setcurrent_filters(
      {
        firms: {},
        companies: {
          Stage: [],
        },
        users: {},
        debts: filters,
        bankers: filters,
        investors: filters,
        // :filters
      }[type] || {}
    );
    setshowing_filters([]);
    // return () => {
    //   setcurrent_filters({ ...filters });
    //   setshowing_filters([]);
    // };
  }, [type]);
  useEffect(() => {
    let headers = [
      {
        id: "photo",
        title: "",
      },
      {
        id: "name",
        title: "Name",
      },
      {
        id: "email",
        title: "Email",
      },
      {
        id: "action",
        title: "Action",
      },
    ];
    let data = [];
    let trimmed_type = type.toLowerCase().slice(0, -1);

    switch (trimmed_type) {
      case "firm":
        data = [
          ...(firms?.data
            .filter((firm) => {
              let f_search = search
                ? (firm.firmName || "").toLowerCase().match(search)
                : true;
              return f_search;
            })
            .map((el) => ({
              ...el,
              website: (
                <a href={el.website} target="_blank">
                  {el.website}
                </a>
              ),
              photo: (
                <div
                  className="photo"
                  style={{ backgroundImage: `url("${el.logo}")` }}
                ></div>
              ),
              action: (
                <div className="action_group">
                  <div
                    className="btn"
                    onClick={() => {
                      setFirmData({
                        ...el,
                        logo: {
                          base64: el.logo,
                          name: el.firmName,
                        },
                      });
                      setmodal({
                        type: "edit",
                      });
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      xlink="http://www.w3.org/1999/xlink"
                      width="512"
                      height="512"
                      x="0"
                      y="0"
                      viewBox="0 0 492.493 492"
                    >
                      <g>
                        <path
                          d="M304.14 82.473 33.165 353.469a10.799 10.799 0 0 0-2.816 4.949L.313 478.973a10.716 10.716 0 0 0 2.816 10.136 10.675 10.675 0 0 0 7.527 3.114 10.6 10.6 0 0 0 2.582-.32l120.555-30.04a10.655 10.655 0 0 0 4.95-2.812l271-270.977zM476.875 45.523 446.711 15.36c-20.16-20.16-55.297-20.14-75.434 0l-36.949 36.95 105.598 105.597 36.949-36.949c10.07-10.066 15.617-23.465 15.617-37.715s-5.547-27.648-15.617-37.719zm0 0"
                          fill="#000000"
                          opacity="1"
                          data-original="#000000"
                        ></path>
                      </g>
                    </svg>
                    Edit
                  </div>
                  <div
                    className="btn delete"
                    onClick={() => {
                      setTo_delete({
                        id: el._id,
                        name: el.firmName,
                        photo: el.logo,
                        type: "Firm",
                      });
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      xlink="http://www.w3.org/1999/xlink"
                      width="20"
                      height="20"
                      x="0"
                      y="0"
                      viewBox="0 0 512 512"
                      class=""
                    >
                      <g>
                        <path
                          d="M424 64h-88V48c0-26.51-21.49-48-48-48h-64c-26.51 0-48 21.49-48 48v16H88c-22.091 0-40 17.909-40 40v32c0 8.837 7.163 16 16 16h384c8.837 0 16-7.163 16-16v-32c0-22.091-17.909-40-40-40zM208 48c0-8.82 7.18-16 16-16h64c8.82 0 16 7.18 16 16v16h-96zM78.364 184a5 5 0 0 0-4.994 5.238l13.2 277.042c1.22 25.64 22.28 45.72 47.94 45.72h242.98c25.66 0 46.72-20.08 47.94-45.72l13.2-277.042a5 5 0 0 0-4.994-5.238zM320 224c0-8.84 7.16-16 16-16s16 7.16 16 16v208c0 8.84-7.16 16-16 16s-16-7.16-16-16zm-80 0c0-8.84 7.16-16 16-16s16 7.16 16 16v208c0 8.84-7.16 16-16 16s-16-7.16-16-16zm-80 0c0-8.84 7.16-16 16-16s16 7.16 16 16v208c0 8.84-7.16 16-16 16s-16-7.16-16-16z"
                          fill="#000000"
                          opacity="1"
                          data-original="#000000"
                          class=""
                        ></path>
                      </g>
                    </svg>
                    Delete
                  </div>
                </div>
              ),
            })) || []),
        ];
        headers = [
          {
            id: "photo",
            title: "",
          },
          {
            id: "firmName",
            title: "Name",
          },
          {
            id: "website",
            title: "Website",
          },
          {
            id: "action",
            title: "Action",
          },
        ];
        break;
      case "user":
        data = [
          ...(users?.data
            .filter((firm) => {
              let f_search = search
                ? [firm.name || "", firm.email || ""]
                    .join(", ")
                    .toLowerCase()
                    .match(search)
                : true;
              return f_search;
            })
            .map((el) => ({
              ...el,
              website: (
                <a href={el.website} target="_blank">
                  {el.website}
                </a>
              ),
              photo: (
                <div
                  className="photo"
                  style={{ backgroundImage: `url("${el.logo}")` }}
                ></div>
              ),
              action: (
                <div className="action_group">
                  <div
                    className="btn"
                    onClick={() => {
                      setuserData({
                        ...el,
                      });
                      setmodal({
                        type: "edit",
                      });
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      xlink="http://www.w3.org/1999/xlink"
                      width="512"
                      height="512"
                      x="0"
                      y="0"
                      viewBox="0 0 492.493 492"
                    >
                      <g>
                        <path
                          d="M304.14 82.473 33.165 353.469a10.799 10.799 0 0 0-2.816 4.949L.313 478.973a10.716 10.716 0 0 0 2.816 10.136 10.675 10.675 0 0 0 7.527 3.114 10.6 10.6 0 0 0 2.582-.32l120.555-30.04a10.655 10.655 0 0 0 4.95-2.812l271-270.977zM476.875 45.523 446.711 15.36c-20.16-20.16-55.297-20.14-75.434 0l-36.949 36.95 105.598 105.597 36.949-36.949c10.07-10.066 15.617-23.465 15.617-37.715s-5.547-27.648-15.617-37.719zm0 0"
                          fill="#000000"
                          opacity="1"
                          data-original="#000000"
                        ></path>
                      </g>
                    </svg>
                    Edit
                  </div>
                  <div
                    className="btn delete"
                    onClick={() => {
                      setTo_delete({
                        id: el._id,
                        name: el.name,
                        photo: "",
                        type: "User",
                      });
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      xlink="http://www.w3.org/1999/xlink"
                      width="20"
                      height="20"
                      x="0"
                      y="0"
                      viewBox="0 0 512 512"
                      class=""
                    >
                      <g>
                        <path
                          d="M424 64h-88V48c0-26.51-21.49-48-48-48h-64c-26.51 0-48 21.49-48 48v16H88c-22.091 0-40 17.909-40 40v32c0 8.837 7.163 16 16 16h384c8.837 0 16-7.163 16-16v-32c0-22.091-17.909-40-40-40zM208 48c0-8.82 7.18-16 16-16h64c8.82 0 16 7.18 16 16v16h-96zM78.364 184a5 5 0 0 0-4.994 5.238l13.2 277.042c1.22 25.64 22.28 45.72 47.94 45.72h242.98c25.66 0 46.72-20.08 47.94-45.72l13.2-277.042a5 5 0 0 0-4.994-5.238zM320 224c0-8.84 7.16-16 16-16s16 7.16 16 16v208c0 8.84-7.16 16-16 16s-16-7.16-16-16zm-80 0c0-8.84 7.16-16 16-16s16 7.16 16 16v208c0 8.84-7.16 16-16 16s-16-7.16-16-16zm-80 0c0-8.84 7.16-16 16-16s16 7.16 16 16v208c0 8.84-7.16 16-16 16s-16-7.16-16-16z"
                          fill="#000000"
                          opacity="1"
                          data-original="#000000"
                          class=""
                        ></path>
                      </g>
                    </svg>
                    Delete
                  </div>
                </div>
              ),
            })) || []),
        ];
        headers = [
          // {
          //   id: "photo",
          //   title: "",
          // },
          {
            id: "name",
            title: "Name",
          },
          {
            id: "email",
            title: "Email",
          },
          {
            id: "type",
            title: "Type",
          },
          {
            id: "action",
            title: "Action",
          },
        ];
        break;
      case "companie":
        {
          let filters = {
            Stage: [],
          };
          data = [
            ...(companies?.data
              .filter((comapny) => {
                let f_search = search
                  ? (comapny?.blurb || "").toLowerCase().match(search)
                  : true;
                let f_stage =
                  current_filters["Stage"] && current_filters["Stage"].length
                    ? current_filters["Stage"].includes(comapny.stage)
                    : true;
                const flag = f_search && f_stage;
                // if (flag) {
                filters["Stage"].push(comapny?.stage);
                // }
                return flag;
              })
              .map((el) => ({
                ...el,
                website: (
                  <a href={el.website} target="_blank">
                    {el.website}
                  </a>
                ),
                investmentManagerId:
                  users?.wrt_id?.[el.investmentManagerId]?.name || "",
                action: (
                  <div className="action_group">
                    <div
                      className="btn"
                      onClick={() => {
                        setcompanyData({
                          ...el,
                        });
                        setmodal({
                          type: "edit",
                        });
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        xlink="http://www.w3.org/1999/xlink"
                        width="512"
                        height="512"
                        x="0"
                        y="0"
                        viewBox="0 0 492.493 492"
                      >
                        <g>
                          <path
                            d="M304.14 82.473 33.165 353.469a10.799 10.799 0 0 0-2.816 4.949L.313 478.973a10.716 10.716 0 0 0 2.816 10.136 10.675 10.675 0 0 0 7.527 3.114 10.6 10.6 0 0 0 2.582-.32l120.555-30.04a10.655 10.655 0 0 0 4.95-2.812l271-270.977zM476.875 45.523 446.711 15.36c-20.16-20.16-55.297-20.14-75.434 0l-36.949 36.95 105.598 105.597 36.949-36.949c10.07-10.066 15.617-23.465 15.617-37.715s-5.547-27.648-15.617-37.719zm0 0"
                            fill="#000000"
                            opacity="1"
                            data-original="#000000"
                          ></path>
                        </g>
                      </svg>
                      Edit
                    </div>
                    <div
                      className="btn delete"
                      onClick={() => {
                        setTo_delete({
                          id: el._id,
                          name: el.name,
                          photo: "",
                          type: "User",
                        });
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        xlink="http://www.w3.org/1999/xlink"
                        width="20"
                        height="20"
                        x="0"
                        y="0"
                        viewBox="0 0 512 512"
                        class=""
                      >
                        <g>
                          <path
                            d="M424 64h-88V48c0-26.51-21.49-48-48-48h-64c-26.51 0-48 21.49-48 48v16H88c-22.091 0-40 17.909-40 40v32c0 8.837 7.163 16 16 16h384c8.837 0 16-7.163 16-16v-32c0-22.091-17.909-40-40-40zM208 48c0-8.82 7.18-16 16-16h64c8.82 0 16 7.18 16 16v16h-96zM78.364 184a5 5 0 0 0-4.994 5.238l13.2 277.042c1.22 25.64 22.28 45.72 47.94 45.72h242.98c25.66 0 46.72-20.08 47.94-45.72l13.2-277.042a5 5 0 0 0-4.994-5.238zM320 224c0-8.84 7.16-16 16-16s16 7.16 16 16v208c0 8.84-7.16 16-16 16s-16-7.16-16-16zm-80 0c0-8.84 7.16-16 16-16s16 7.16 16 16v208c0 8.84-7.16 16-16 16s-16-7.16-16-16zm-80 0c0-8.84 7.16-16 16-16s16 7.16 16 16v208c0 8.84-7.16 16-16 16s-16-7.16-16-16z"
                            fill="#000000"
                            opacity="1"
                            data-original="#000000"
                            class=""
                          ></path>
                        </g>
                      </svg>
                      Delete
                    </div>
                  </div>
                ),
              })) || []),
          ];
          setshowing_filters([
            {
              title: "Stage",
              children: [...new Set(filters["Stage"])].sort((a, b) =>
                a.localeCompare(b)
              ),
            },
          ]);

          //
          headers = [
            {
              id: "location",
              title: "Location",
            },
            {
              id: "website",
              title: "Website",
            },
            {
              id: "investmentManagerId",
              title: "Manager",
            },
            {
              id: "blurb",
              title: "BLURB",
            },
            {
              id: "stage",
              title: "Stage",
            },
            {
              id: "action",
              title: "Action",
            },
          ];
        }
        break;
      case "introduction":
        headers = [
          {
            id: "photo",
            title: "",
          },
          {
            id: "investername",
            title: "Invester Name",
          },
          {
            id: "foundername",
            title: "Founder Name",
          },
          {
            id: "marked",
            title: "Marked",
          },
          {
            id: "emailed",
            title: "Emailed",
          },
          {
            id: "action",
            title: "Action",
          },
        ];

        data = [
          ...(introductions.data
            .filter((introduction) => {
              let f_search = search
                ? [
                    introduction.investorId.name || "",
                    introduction.userId.name || "",
                  ]
                    .join(", ")
                    .toLowerCase()
                    .match(search)
                : true;
              return f_search;
            })
            .map((el) => ({
              ...el,
              investername: el.investorId.name,
              foundername: el.userId.name,
              photo: (
                <div
                  className="photo"
                  style={{ backgroundImage: `url("${el?.investorId?.photo}")` }}
                ></div>
              ),
              marked: (
                <>
                  {el.mark ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      xlink="http://www.w3.org/1999/xlink"
                      width="20"
                      height="20"
                      x="0"
                      y="0"
                      viewBox="0 0 24 24"
                      class=""
                    >
                      <g>
                        <path
                          d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"
                          fill="green"
                          opacity="1"
                          data-original="green"
                          class=""
                        ></path>
                      </g>
                    </svg>
                  ) : (
                    ""
                  )}
                </>
              ),
              emailed: (
                <>
                  {el.email ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      xlink="http://www.w3.org/1999/xlink"
                      width="20"
                      height="20"
                      x="0"
                      y="0"
                      viewBox="0 0 24 24"
                      class=""
                    >
                      <g>
                        <path
                          d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"
                          fill="green"
                          opacity="1"
                          data-original="green"
                          class=""
                        ></path>
                      </g>
                    </svg>
                  ) : (
                    ""
                  )}
                </>
              ),
              action: (
                <div className="action_group">
                  <div
                    className="btn"
                    onClick={() => {
                      setintroductionData({
                        ...el,
                        investerphoto: {
                          base64: el?.investorId?.photo,
                          name: el.investorId.name,
                        },
                        investerfirmphoto: {
                          base64: firms.wrt[el?.investorId?.firmId]?.logo,
                          name: firms.wrt[el?.investorId?.firmId]?.firmName,
                        },
                        investorName: el.investorId.name, // required
                        investorEmail: el.investorId.email, // required

                        investorFirm:
                          firms.wrt[el?.investorId?.firmId].firmName, // required

                        // companyName: el.investorId.firm, // required
                        founderName: el.userId.name, // required
                        founderEmail: el.userId.email, // required
                      });
                      setmodal({
                        type: "edit",
                      });
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      xlink="http://www.w3.org/1999/xlink"
                      width="460"
                      height="453"
                      x="0"
                      y="0"
                      viewBox="0 0 519.643 519.643"
                      class=""
                    >
                      <g>
                        <circle
                          cx="259.823"
                          cy="259.866"
                          r="80"
                          fill="#000000"
                          opacity="1"
                          data-original="#000000"
                          class=""
                        ></circle>
                        <path
                          d="M511.673 237.706c-61.494-74.31-154.579-145.84-251.85-145.84-97.29 0-190.397 71.58-251.85 145.84-10.63 12.84-10.63 31.48 0 44.32 15.45 18.67 47.84 54.71 91.1 86.2 108.949 79.312 212.311 79.487 321.5 0 43.26-31.49 75.65-67.53 91.1-86.2 10.599-12.815 10.654-31.438 0-44.32zm-251.85-89.84c61.76 0 112 50.24 112 112s-50.24 112-112 112-112-50.24-112-112 50.24-112 112-112z"
                          fill="#000000"
                          opacity="1"
                          data-original="#000000"
                          class=""
                        ></path>
                      </g>
                    </svg>
                    View
                  </div>
                  <div
                    className="btn delete"
                    onClick={() => {
                      setTo_delete({
                        id: el._id,
                        name: el.investorId.name,
                        photo: el.investorId.photo,
                        type: "User",
                      });
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      xlink="http://www.w3.org/1999/xlink"
                      width="20"
                      height="20"
                      x="0"
                      y="0"
                      viewBox="0 0 512 512"
                      class=""
                    >
                      <g>
                        <path
                          d="M424 64h-88V48c0-26.51-21.49-48-48-48h-64c-26.51 0-48 21.49-48 48v16H88c-22.091 0-40 17.909-40 40v32c0 8.837 7.163 16 16 16h384c8.837 0 16-7.163 16-16v-32c0-22.091-17.909-40-40-40zM208 48c0-8.82 7.18-16 16-16h64c8.82 0 16 7.18 16 16v16h-96zM78.364 184a5 5 0 0 0-4.994 5.238l13.2 277.042c1.22 25.64 22.28 45.72 47.94 45.72h242.98c25.66 0 46.72-20.08 47.94-45.72l13.2-277.042a5 5 0 0 0-4.994-5.238zM320 224c0-8.84 7.16-16 16-16s16 7.16 16 16v208c0 8.84-7.16 16-16 16s-16-7.16-16-16zm-80 0c0-8.84 7.16-16 16-16s16 7.16 16 16v208c0 8.84-7.16 16-16 16s-16-7.16-16-16zm-80 0c0-8.84 7.16-16 16-16s16 7.16 16 16v208c0 8.84-7.16 16-16 16s-16-7.16-16-16z"
                          fill="#000000"
                          opacity="1"
                          data-original="#000000"
                          class=""
                        ></path>
                      </g>
                    </svg>
                    Delete
                  </div>
                </div>
              ),
            })) || []),
        ];
        break;
      default:
        let filters = {
          "Funding Round": [],
          Sector: [],
          City: [],
          Country: [],
          Designation: [],
        };
        data = [
          ...(investors?.data
            // .filter((el) => el.type.toLowerCase() == trimmed_type.toLowerCase())
            .filter((people) => {
              let f_type = type
                ? people.type.toLowerCase() == trimmed_type
                : true;
              let f_fundingRound =
                current_filters["Funding Round"] &&
                current_filters["Funding Round"].length
                  ? people?.stage?.some((ele) =>
                      current_filters["Funding Round"].includes(ele.trim())
                    )
                  : true;
              let f_sector =
                current_filters["Sector"] && current_filters["Sector"].length
                  ? people?.sector?.some((ele) =>
                      current_filters["Sector"].includes(ele.trim())
                    )
                  : true;

              let f_city =
                current_filters["City"] && current_filters["City"].length
                  ? people?.locationCities?.some((ele) =>
                      current_filters["City"].includes(ele.trim())
                    )
                  : true;
              let f_country =
                current_filters["Country"] && current_filters["Country"].length
                  ? people?.locationCountries?.some((ele) =>
                      current_filters["Country"].includes(ele.trim())
                    )
                  : true;
              let f_designation =
                current_filters["Designation"] &&
                current_filters["Designation"].length
                  ? current_filters["Designation"].includes(
                      people?.designation.trim()
                    )
                  : true;

              let f_search = search
                ? [
                    people.name || "",
                    people.locationCountries || "",
                    people.designation || "",
                    // item.name,
                  ]
                    .join(", ")
                    .toLowerCase()
                    .match(search)
                : true;
              let flag =
                f_type &&
                f_fundingRound &&
                f_sector &&
                f_city &&
                f_country &&
                f_designation &&
                f_search;
              if (flag) {
                filters["Funding Round"].push(...(people?.stage || []));
                filters["Sector"].push(...(people?.sector || []));
                filters["City"].push(...(people?.locationCities || []));
                filters["Country"].push(...(people?.locationCountries || []));
                filters["Designation"].push(people?.designation || "");
              }
              return flag;
            })
            .map((el) => ({
              ...el,
              photo: (
                <div
                  className="photo"
                  style={{ backgroundImage: `url("${el.photo}")` }}
                ></div>
              ),
              action: (
                <div className="action_group">
                  <div
                    className="btn"
                    onClick={() => {
                      setInvestorsData({
                        ...el,
                        photo: {
                          base64: el.logo,
                          name: el.firmName,
                        },
                        cities: el.locationCities,
                        countries: el.locationCountries,
                      });
                      setmodal({
                        type: "edit",
                      });
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      xlink="http://www.w3.org/1999/xlink"
                      width="512"
                      height="512"
                      x="0"
                      y="0"
                      viewBox="0 0 492.493 492"
                    >
                      <g>
                        <path
                          d="M304.14 82.473 33.165 353.469a10.799 10.799 0 0 0-2.816 4.949L.313 478.973a10.716 10.716 0 0 0 2.816 10.136 10.675 10.675 0 0 0 7.527 3.114 10.6 10.6 0 0 0 2.582-.32l120.555-30.04a10.655 10.655 0 0 0 4.95-2.812l271-270.977zM476.875 45.523 446.711 15.36c-20.16-20.16-55.297-20.14-75.434 0l-36.949 36.95 105.598 105.597 36.949-36.949c10.07-10.066 15.617-23.465 15.617-37.715s-5.547-27.648-15.617-37.719zm0 0"
                          fill="#000000"
                          opacity="1"
                          data-original="#000000"
                        ></path>
                      </g>
                    </svg>
                    Edit
                  </div>
                  <div
                    className="btn delete"
                    onClick={() => {
                      setTo_delete({
                        id: el._id,
                        name: el.name,
                        photo: el.photo,
                        type: el.type,
                      });
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      xlink="http://www.w3.org/1999/xlink"
                      width="20"
                      height="20"
                      x="0"
                      y="0"
                      viewBox="0 0 512 512"
                      class=""
                    >
                      <g>
                        <path
                          d="M424 64h-88V48c0-26.51-21.49-48-48-48h-64c-26.51 0-48 21.49-48 48v16H88c-22.091 0-40 17.909-40 40v32c0 8.837 7.163 16 16 16h384c8.837 0 16-7.163 16-16v-32c0-22.091-17.909-40-40-40zM208 48c0-8.82 7.18-16 16-16h64c8.82 0 16 7.18 16 16v16h-96zM78.364 184a5 5 0 0 0-4.994 5.238l13.2 277.042c1.22 25.64 22.28 45.72 47.94 45.72h242.98c25.66 0 46.72-20.08 47.94-45.72l13.2-277.042a5 5 0 0 0-4.994-5.238zM320 224c0-8.84 7.16-16 16-16s16 7.16 16 16v208c0 8.84-7.16 16-16 16s-16-7.16-16-16zm-80 0c0-8.84 7.16-16 16-16s16 7.16 16 16v208c0 8.84-7.16 16-16 16s-16-7.16-16-16zm-80 0c0-8.84 7.16-16 16-16s16 7.16 16 16v208c0 8.84-7.16 16-16 16s-16-7.16-16-16z"
                          fill="#000000"
                          opacity="1"
                          data-original="#000000"
                          class=""
                        ></path>
                      </g>
                    </svg>
                    Delete
                  </div>
                </div>
              ),
            })) || []),
        ];
        setshowing_filters([
          {
            title: "Funding Round",
            children: [...new Set(filters["Funding Round"])].sort((a, b) =>
              a.localeCompare(b)
            ),
            count: filters["Funding Round"].length,
          },
          {
            title: "Sector",
            children: [...new Set(filters["Sector"])].sort((a, b) =>
              a.localeCompare(b)
            ),
            count: filters["Sector"].length,
          },
          {
            title: "City",
            children: [...new Set(filters["City"])].sort((a, b) =>
              a.localeCompare(b)
            ),
            count: filters["City"].length,
          },
          {
            title: "Country",
            children: [...new Set(filters["Country"])].sort((a, b) =>
              a.localeCompare(b)
            ),
            count: filters["Country"].length,
          },
          {
            title: "Designation",
            children: [...new Set(filters["Designation"])].sort((a, b) =>
              a.localeCompare(b)
            ),
            count: filters["Designation"].length,
          },
        ]);
    }

    settable_header(headers);
    settable_data(data);
    setInvestorsData((prev) => ({
      ...prev,
      type: {
        investors: "Investor",
        debts: "Debt",
        bankers: "Banker",
      }[type],
    }));
    return () => {
      settable_header([]);
      settable_data({});
    };
  }, [
    investors,
    firms,
    users,
    companies,
    introductions,
    type,
    search,
    current_filters,
  ]);
  // console.log(type);

  return (
    <>
      <div className="navbar">
        <div className="navbar_main">
          <div className="nav_list">
            {[
              "Investors",
              "Bankers",
              "Debts",
              "Users",
              "Companies",
              "Firms",
              "Introductions",
            ].map((el) => (
              <div
                className="nav_items"
                active={String(
                  !!location?.pathname?.match(
                    el.replaceAll(" ", "-").toLowerCase()
                  )
                )}
                key={el}
                onClick={() => {
                  navigate(`../admin/${el.replaceAll(" ", "-").toLowerCase()}`);
                }}
              >
                {el}
              </div>
            ))}
          </div>
          <div style={{ margin: "0 0 0 auto" }}></div>
          {false && (
            <SearchAndFilter
              {...{
                search,
                setsearch,
                showing_filters,
                current_filters,
                setcurrent_filters,
                // filter_modal: false,
                filter_modal: type != "firms",
              }}
            />
          )}
          {type != "introductions" ? (
            <div
              className="add_type"
              onClick={() => {
                setmodal({
                  type: "add",
                });
              }}
            >
              <svg width="20" height="20" x="0" y="0" viewBox="0 0 448 448">
                <g>
                  <path
                    d="M408 184H272a8 8 0 0 1-8-8V40c0-22.09-17.91-40-40-40s-40 17.91-40 40v136a8 8 0 0 1-8 8H40c-22.09 0-40 17.91-40 40s17.91 40 40 40h136a8 8 0 0 1 8 8v136c0 22.09 17.91 40 40 40s40-17.91 40-40V272a8 8 0 0 1 8-8h136c22.09 0 40-17.91 40-40s-17.91-40-40-40zm0 0"
                    fill="#000000"
                    opacity="1"
                    data-original="#000000"
                    class="hovered-path"
                  ></path>
                </g>
              </svg>
              Add {type.slice(0, -1)}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="admin_body">
        <Table
          {...{
            data: [...table_data],
            header: [...table_header],
          }}
        />
        {to_delete?.id ? (
          <div className="delete_modal">
            <div className="delete_modal_main">
              <div className="modal_txt">
                Do you want to delete this
                <span> {type.slice(0, -1)} </span>?
              </div>
              <div className="delete_details">
                {to_delete?.photo ? (
                  <div
                    className="details_photo"
                    style={{
                      backgroundImage: `url("${to_delete?.photo || ""}")`,
                    }}
                  ></div>
                ) : (
                  ""
                )}
                <div className="details_name">{to_delete.name}</div>
              </div>
              <div className="btn_group">
                <div
                  className="modal_btn delete"
                  onClick={() => {
                    let config = {
                      method: "delete",
                      maxBodyLength: Infinity,
                      url:
                        ({
                          firms:
                            "https://accel-be.test-server.app/api/investment-firms/",
                          users: "https://accel-be.test-server.app/api/users/",
                          companies:
                            "https://accel-be.test-server.app/api/companies/",
                          introductions:
                            "https://accel-be.test-server.app/api/introductions/",
                        }[type] ||
                          "https://accel-be.test-server.app/api/investors/") +
                        to_delete.id,
                      headers: {
                        "Content-Type": "application/json",
                      },
                    };

                    axios
                      .request(config)
                      .then((response) => {
                        // dispatch(getInvestorsWithFirms());
                        // if (to_delete.type == "Firm") {
                        //   return dispatch(getFirms());
                        // }
                        // if (to_delete.type == "Firm") {
                        dispatch(
                          {
                            firms: getFirms(),
                            users: getUsers(),
                            companies: getCompanies(),
                            introductions: getIntroductions(),
                          }[type] || getInvestors()
                        );
                        return setTo_delete({});
                        // }
                        // dispatch(getInvestors());
                      })
                      .catch((error) => {});
                  }}
                >
                  Delete
                </div>
                <div className="modal_btn" onClick={() => setTo_delete({})}>
                  Cancel
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      {modal?.type ? (
        <CustForm
          {...{
            type: (type != "introductions" ? modal.type : "View") + " " + type, //edit//view
            inputs: {
              firms: [
                {
                  type: "text",
                  label: "Firm Name",
                  Statekey: "firmName",
                  required: true,
                },
                {
                  type: "text",
                  label: "Website",
                  Statekey: "website",
                  required: true,
                },
                {
                  type: "image",
                  label: "Logo",
                  Statekey: "logo",
                  required: true,
                },
              ],
              users: [
                {
                  type: "text",
                  label: "Name",
                  Statekey: "name",
                  required: true,
                },
                {
                  type: "text",
                  label: "Email",
                  Statekey: "email",
                  required: true,
                },
                {
                  type: "image",
                  label: "Photo",
                  Statekey: "photo",
                },
                {
                  required: userData.type == "Founder",
                  type: "singleselectwithsearch",
                  label: "Company",
                  Statekey: "companyId",
                  options:
                    companies?.data?.map(({ _id, blurb, photo }) => ({
                      id: _id,
                      name: blurb,
                      show: (
                        <div className="display_group">
                          {/* <div
                            className="photo"
                            style={{
                              backgroundImage: `url("${photo || ""}")`,
                            }}
                          ></div> */}
                          <div className="txt">{blurb || ""}</div>
                        </div>
                      ),
                    })) || [],
                  option_show: (id) => (
                    <div className="display_group">
                      {/* <div
                        className="photo"
                        style={{
                          backgroundImage: `url("${
                            users.wrt_id?.[id]?.photo || ""
                          }")`,
                        }}
                      ></div> */}
                      <div className="txt">
                        {companies.wrt_id?.[id]?.blurb || ""}
                      </div>
                    </div>
                  ),
                },
                {
                  type: "singleselectchips",
                  label: "Type",
                  Statekey: "type",
                  required: true,
                  options: [
                    {
                      id: "Founder",
                      name: "Founder",
                    },
                    {
                      id: "Manager",
                      name: "Manager",
                    },
                    {
                      id: "Admin",
                      name: "Admin",
                    },
                  ],
                },
                {
                  type: "text",
                  label: "LinkedIn",
                  Statekey: "linkedinn",
                },
              ],
              companies: [
                {
                  type: "text",
                  label: "Location",
                  Statekey: "location",
                  required: true,
                },
                {
                  type: "text",
                  label: "Website",
                  Statekey: "website",
                  required: true,
                },
                {
                  required: true,
                  type: "singleselectwithsearch",
                  label: "Manager",
                  Statekey: "investmentManagerId",
                  options:
                    users?.data?.map(({ _id, name, photo }) => ({
                      id: _id,
                      name: name,
                      show: (
                        <div className="display_group">
                          <div
                            className="photo"
                            style={{
                              backgroundImage: `url("${photo || ""}")`,
                            }}
                          ></div>
                          <div className="txt">{name || ""}</div>
                        </div>
                      ),
                    })) || [],
                  option_show: (id) => (
                    <div className="display_group">
                      <div
                        className="photo"
                        style={{
                          backgroundImage: `url("${
                            users.wrt_id?.[id]?.photo || ""
                          }")`,
                        }}
                      ></div>
                      <div className="txt">
                        {users.wrt_id?.[id]?.name || ""}
                      </div>
                    </div>
                  ),
                },
                {
                  type: "text",
                  label: "BLURB",
                  Statekey: "blurb",
                  required: true,
                  // required: true,
                },
                {
                  type: "singleselectchips",
                  label: "Stage",
                  Statekey: "stage",
                  required: true,
                  options: [
                    {
                      id: "Buyout",
                      name: "Buyout",
                    },
                    {
                      id: "Early",
                      name: "Early",
                    },
                    {
                      id: "Growth",
                      name: "Growth",
                    },
                    {
                      id: "Late",
                      name: "Late",
                    },
                    {
                      id: "Mid market",
                      name: "Mid market",
                    },
                    {
                      id: "Pre-IPO",
                      name: "Pre-IPO",
                    },
                  ],
                },
              ],
              introductions: [
                {
                  type: "imageview",
                  label: "Invester Name and Photo",
                  Statekey: "investerphoto",
                },
                {
                  type: "imageview",
                  label: "Invester Firm Name and Photo",
                  Statekey: "investerfirmphoto",
                },

                // {
                //   type: "viewtext",
                //   label: "Invester Name",
                //   Statekey: "investorName",
                //   required: true,
                // },
                {
                  type: "viewtext",
                  label: "Invester Email",
                  Statekey: "investorEmail",
                  required: false,
                },
                // {
                //   type: "viewtext",
                //   label: "Company Name",
                //   Statekey: "companyName",
                //   required: true,
                // },
                {
                  type: "viewtext",
                  label: "Founder Name",
                  Statekey: "founderName",
                  required: false,
                },
                {
                  type: "viewtext",
                  label: "Founder Email",
                  Statekey: "founderEmail",
                  required: false,
                },
              ],
            }[type] || [
              {
                required: true,
                type: "singleselectwithsearch",
                label: "Firm",
                Statekey: "firmId",
                options:
                  firms?.data?.map(({ _id, firmName, logo }) => ({
                    id: _id,
                    name: firmName,
                    show: (
                      <div className="display_group">
                        <div
                          className="photo"
                          style={{
                            backgroundImage: `url("${logo || ""}")`,
                          }}
                        ></div>
                        <div className="txt">{firmName || ""}</div>
                      </div>
                    ),
                  })) || [],
                option_show: (id) => (
                  <div className="display_group">
                    <div
                      className="photo"
                      style={{
                        backgroundImage: `url("${
                          firm_wrt_id?.[id]?.logo || ""
                        }")`,
                      }}
                    ></div>
                    <div className="txt">
                      {firm_wrt_id?.[id]?.firmName || ""}
                    </div>
                  </div>
                ),
              },
              {
                type: "singleselectchips",
                label: "Type",
                Statekey: "type",
                options: [
                  {
                    id: "Investor",
                    name: "Investor",
                  },
                  {
                    id: "Banker",
                    name: "Banker",
                  },
                  {
                    id: "Debt",
                    name: "Debt",
                  },
                ],
              },
              {
                required: true,
                type: "multiselect",
                label: "Stage",
                Statekey: "stage",
                options: [
                  {
                    id: "Buyout",
                    name: "Buyout",
                  },
                  {
                    id: "Early Stage",
                    name: "Early Stage",
                  },
                  {
                    id: "Growth Stage",
                    name: "Growth Stage",
                  },
                  {
                    id: "Late Stage",
                    name: "Late Stage",
                  },
                  {
                    id: "Mid market",
                    name: "Mid market",
                  },
                  {
                    id: "Pre-IPO",
                    name: "Pre-IPO",
                  },
                ],
              },
              {
                type: "multiselectwithsearch",
                label: "Sector",
                Statekey: "sector",
                options: [
                  { id: "Agritech", name: "Agritech" },
                  { id: "Automobile", name: "Automobile" },
                  { id: "B2B", name: "B2B" },
                  { id: "Chemicals", name: "Chemicals" },
                  { id: "Consumer", name: "Consumer" },
                  { id: "Edtech", name: "Edtech" },
                  { id: "Fintech", name: "Fintech" },
                  { id: "Healthcare", name: "Healthcare" },
                  { id: "Metal", name: "Metal" },
                  { id: "Real Estate", name: "Real Estate" },
                  { id: "SaaS", name: "SaaS" },
                  { id: "Sector Agnostic", name: "Sector Agnostic" },
                  { id: "Sustainability", name: "Sustainability" },
                  { id: "Technology", name: "Technology" },
                ],
              },
              {
                type: "text",
                label: "Name",
                Statekey: "name",
                required: true,
              },
              {
                required: true,
                type: "text",
                label: "Email",
                Statekey: "email",
                required: true,
              },
              {
                type: "image",
                label: "Photo",
                Statekey: "photo",
              },
              {
                type: "addchipswithinput",
                label: "Cities",
                Statekey: "cities",
                options: [],
                // showing_filters?.filter((el) => el.title == "City")?.[0]
                //   ?.children || [],
              },
              {
                type: "addchipswithinput",
                label: "Countries",
                Statekey: "countries",
                options: [],
                //  showing_filters?.filter(
                //   (el) => el.title == "Country"
                // )?.[0]?.children,
              },
              {
                type: "text",
                label: "Designation",
                Statekey: "designation",
              },
              {
                type: "text",
                label: "LinkedIn",
                Statekey: "linkedIn",
              },
              {
                type: "addchipswithinput",
                label: "Past Investments",
                Statekey: "pastInvestments",
                options: [],
                // firms?.data?.map(({ _id, firmName }) => firmName) || [],
              },
            ],
            setdata:
              {
                firms: setFirmData,
                users: setuserData,
                companies: setcompanyData,
                introductions: setintroductionData,
              }[type] || setInvestorsData,
            data:
              {
                firms: firmData,
                users: userData,
                companies: companyData,
                introductions: introductionData,
              }[type] || investorsData,
            is_saving: modal.is_saving,
            actions: {
              introductions:
                introductions?.wrt[introductionData?._id]?.mark &&
                introductions?.wrt[introductionData?._id]?.email
                  ? [
                      { type: "markedandemailed", btntype: "done" },
                      { type: "close", btntype: "" },
                    ]
                  : introductions?.wrt[introductionData?._id]?.mark
                  ? [
                      { type: "email", btntype: "dark" },
                      { type: "marked", btntype: "done" },
                      { type: "close", btntype: "" },
                    ]
                  : [
                      { type: "markandemail", btntype: "dark" },
                      { type: "mark", btntype: "dark" },
                      { type: "close", btntype: "" },
                    ],
            }[type] || [
              { type: "save", btntype: "dark" },
              { type: "close", btntype: "" },
            ],
            save: () => {
              if (type == "firms") {
                let formatted_data = {
                  ...firmData,
                  type: "Firm",
                  logo: "https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png",
                };
                setmodal((prev) => ({ ...prev, is_saving: true }));
                let config = {
                  method: modal.type == "edit" ? "put" : "post",
                  maxBodyLength: Infinity,
                  url:
                    "https://accel-be.test-server.app/api/investment-firms" +
                    (modal.type == "edit" ? "/" + firmData._id : ""),
                  headers: {
                    "Content-Type": "application/json",
                  },
                  data: formatted_data,
                };

                axios
                  .request(config)
                  .then((response) => {
                    setmodal((prev) => ({ type: "", is_saving: false }));
                    dispatch(getInvestorsWithFirms());
                    // dispatch(getInvestors());
                    dispatch(getFirms());
                  })
                  .catch((error) => {});
                return;
              }
              if (type == "users") {
                let formatted_data = {
                  ...userData,
                };
                console.log(formatted_data);

                setmodal((prev) => ({ ...prev, is_saving: true }));
                let config = {
                  method: modal.type == "edit" ? "put" : "post",
                  maxBodyLength: Infinity,
                  url:
                    "https://accel-be.test-server.app/api/users" +
                    (modal.type == "edit" ? "/" + userData._id : ""),
                  headers: {
                    "Content-Type": "application/json",
                  },
                  data: formatted_data,
                };

                axios
                  .request(config)
                  .then((response) => {
                    setmodal((prev) => ({ type: "", is_saving: false }));
                    dispatch(getUsers());
                  })
                  .catch((error) => {});
                return;
              }
              if (type == "companies") {
                let formatted_data = {
                  ...companyData,
                };
                setmodal((prev) => ({ ...prev, is_saving: true }));
                let config = {
                  method: modal.type == "edit" ? "put" : "post",
                  maxBodyLength: Infinity,
                  url:
                    "https://accel-be.test-server.app/api/companies" +
                    (modal.type == "edit" ? "/" + companyData._id : ""),
                  headers: {
                    "Content-Type": "application/json",
                  },
                  data: formatted_data,
                };

                axios
                  .request(config)
                  .then((response) => {
                    setmodal((prev) => ({ type: "", is_saving: false }));
                    dispatch(getCompanies());
                  })
                  .catch((error) => {});
                return;
              }
              let formatted_data = {
                ...investorsData,
                photo:
                  "https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png",

                locationCities: investorsData.cities,
                locationCountries: investorsData.countries,
              };
              setmodal((prev) => ({ ...prev, is_saving: true }));
              let config = {
                method: modal.type == "edit" ? "put" : "post",
                maxBodyLength: Infinity,
                url:
                  "https://accel-be.test-server.app/api/investors" +
                  (modal.type == "edit" ? "/" + investorsData._id : ""),
                headers: {
                  "Content-Type": "application/json",
                },
                data: formatted_data,
              };

              axios
                .request(config)
                .then((response) => {
                  setmodal((prev) => ({ type: "", is_saving: true }));
                  dispatch(getInvestorsWithFirms());
                  dispatch(getInvestors());
                  // dispatch(getFirms());
                })
                .catch((error) => {});
            },
            close: () => {
              setmodal({
                type: "",
                is_saving: false,
              });
              setInvestorsData(initialInvestorData);
              setFirmData(initialFirmData);
              setuserData(initialuserData);
              setcompanyData(initialCompaniesData);
            },
            mark: () => {
              console.log("Dhiraj");

              dispatch(markIntroduction(introductionData));
            },
            markandemail: () => {
              dispatch(emailIntroduction(introductionData));
              dispatch(markIntroduction(introductionData));
            },
            deleteentry: () => {},
          }}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default Admin;

import React, { useCallback, useLayoutEffect } from "react";
import { useEffect } from "react";

import { useEditor, EditorContent } from "@tiptap/react";
import Bold from "@tiptap/extension-bold";
import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import Code from "@tiptap/extension-code";
import Italic from "@tiptap/extension-italic";
import Link from "@tiptap/extension-link";
import Highlight from "@tiptap/extension-highlight";
import Strike from "@tiptap/extension-strike";
import Underline from "@tiptap/extension-underline";
import TextStyle from "@tiptap/extension-text-style";
import Color from "@tiptap/extension-color";
import ListKeymap from "@tiptap/extension-list-keymap";
import BulletList from "@tiptap/extension-bullet-list";
import ListItem from "@tiptap/extension-list-item";
import Placeholder from "@tiptap/extension-placeholder";

import Mention from "@tiptap/extension-mention";
import suggestion from "./suggestion";
import OrderedList from "@tiptap/extension-ordered-list";

// const suggestions = [
//   "_id",
//   "stage",
//   "firmId",
//   "name",
//   "email",
//   "photo",
//   "locationCities",
//   "locationCountries",
//   "designation",
//   "sector",
//   "linkedIn",
//   "pastInvestments",
//   "type",
//   "isLiked",
// ];
export const RTE_Remasterd = ({
  formatting,
  suggestions = ["name"],
  settxt,
}) => {
  const editor = useEditor({
    extensions: [
      Document,
      Paragraph,
      Text,
      ...(suggestions.length
        ? [
            Mention.configure({
              HTMLAttributes: {
                class: "mention",
              },
              renderHTML: ({ node }) => {
                const { label, id } = node.attrs;
                return [
                  "span",
                  {
                    class: "dynamic-variable-button",
                    // "data-variable": label || id,
                  },
                  `{${label || id}}`,
                ];
              },
              suggestion: {
                ...suggestion,
                items: ({ query, editor }) => {
                  return editor.options.editorProps.items?.filter((item) => {
                    let t_item = item.toLowerCase();
                    let t_query = query.toLowerCase();

                    return t_item.includes(t_query);
                  });
                },
              },
            }),
          ]
        : []),
      Placeholder.configure({
        placeholder: "Write something …",
      }),
      Document,
      Text,
      Paragraph,
      ...(formatting
        ? [
            Bold.configure({
              HTMLAttributes: {
                class: "custom_bold",
              },
            }),
            Code,
            Strike,
            Italic,
            Link.configure({
              openOnClick: true,
              autolink: false,
              defaultProtocol: "https",
              linkOnPaste: false,
            }),
            Highlight.configure({ multicolor: true }),
            Underline,
            TextStyle,
            Color,
            BulletList,
            OrderedList,
            ListItem,
            ListKeymap,
          ]
        : []),
    ],
    content: `<p></p>`,
    onUpdate: ({ editor }) => {
      // setValue(editor.getJSON());
      settxt(editor.getHTML());
      localStorage.setItem("mailData", editor.getHTML());
    },
  });

  // useEffect(() => {
  //   if (editor && suggestions.length > 0) {
  //     editor.extensionManager.extensions.forEach((ext) => {
  //       if (ext.name === "mention") {
  //         ext.update({
  //           suggestion: {
  //             ...suggestion,
  //             items: ({ query, editor }) =>
  //               editor.options.editorProps.items.filter((item) => {
  //                 let t_item = item.toLowerCase();
  //                 let t_query = query.toLowerCase();

  //                 return t_item.includes(t_query);
  //               }),
  //           },
  //         });
  //       }
  //     });
  //   }
  // }, [suggestions]);

  useLayoutEffect(() => {
    let dataFromLocal = localStorage.getItem("mailData");
    if (editor && dataFromLocal) {
      editor.commands.setContent(dataFromLocal, false, {
        preserveWhitespace: "full",
      });
      settxt(dataFromLocal);
    }
    if (editor && suggestions.length > 0) {
      editor.setOptions({
        editorProps: {
          items:
            suggestions?.filter(
              (el) => !"_id,photo,isLiked,firmId".includes(el)
            ) || [],
        },
      });
    }

    return () => {
      if (editor) {
        editor.destroy();
      }
    };
  }, [suggestions, editor]);
  const setLink = useCallback(() => {
    const previousUrl = editor.getAttributes("link").href;
    const url = window.prompt("URL", previousUrl);

    // cancelled
    if (url === null) {
      return;
    }

    // empty
    if (url === "") {
      editor.chain().focus().extendMarkRange("link").unsetLink().run();
      return;
    }

    // update link
    editor.chain().focus().extendMarkRange("link").setLink({ href: url }).run();
  }, [editor]);

  return (
    <div
      className="rich_text_editor"
      // if="editor-main"
      onClick={(e) => {
        if (!editor.isFocused) {
          editor.commands.focus("end");
        }
      }}
    >
      {/* <pre>{JSON.stringify(suggestions, undefined, 2)}</pre> */}
      <EditorContent editor={editor} class="editor_main" />
      {formatting ? (
        <div className="control-group" onClick={(e) => e.stopPropagation()}>
          <button
            onClick={() => editor.chain().focus().toggleBold().run()}
            className={editor.isActive("bold") ? "is-active" : ""}
          >
            <img src="https://www.gstatic.com/images/icons/material/system_gm/1x/format_bold_black_20dp.png" />
          </button>
          <button
            onClick={() => editor.chain().focus().toggleItalic().run()}
            className={editor.isActive("italic") ? "is-active" : ""}
          >
            <img src="https://www.gstatic.com/images/icons/material/system_gm/1x/format_italic_black_20dp.png" />
          </button>
          <button
            onClick={() => editor.chain().focus().toggleUnderline().run()}
            className={editor.isActive("underline") ? "is-active" : ""}
          >
            <img src="https://www.gstatic.com/images/icons/material/system_gm/1x/format_underlined_black_20dp.png" />
          </button>
          <button
            onClick={() => editor.chain().focus().toggleBulletList().run()}
            className={editor.isActive("bulletList") ? "is-active" : ""}
          >
            <img src="https://www.gstatic.com/images/icons/material/system_gm/1x/format_list_bulleted_black_20dp.png" />
          </button>
          <button
            onClick={() => editor.chain().focus().toggleOrderedList().run()}
            className={editor.isActive("bulletList") ? "is-active" : ""}
          >
            <img src="https://www.gstatic.com/images/icons/material/system_gm/1x/format_list_numbered_black_20dp.png" />
          </button>
          <button
            onClick={() => editor.chain().focus().toggleStrike().run()}
            className={editor.isActive("strike") ? "is-active" : ""}
          >
            <img src="https://www.gstatic.com/images/icons/material/system_gm/1x/strikethrough_s_black_20dp.png" />
          </button>
          <label className="highlight_group" htmlFor="text-color-input">
            <svg
              style={{ width: "calc(100% - 13px)" }}
              xmlns="http://www.w3.org/2000/svg"
              width="417"
              height="383"
              viewBox="0 0 417 383"
              fill="none"
            >
              <path
                d="M91.0091 228.6L64.6091 255V319L0.289062 383H64.6091L96.7691 351H161.089L187.489 324.6L91.0091 228.6ZM402.209 43.1601L373.889 15.0001C369.419 10.4283 364.08 6.79574 358.186 4.31569C352.293 1.83564 345.963 0.558105 339.569 0.558105C333.175 0.558105 326.845 1.83564 320.952 4.31569C315.058 6.79574 309.719 10.4283 305.249 15.0001L102.369 217.4L198.369 313.4L402.209 111C411.198 102 416.247 89.8002 416.247 77.0801C416.247 64.3601 411.198 52.1602 402.209 43.1601Z"
                fill="black"
              />
            </svg>
            <input
              id="text-color-input"
              type="color"
              onInput={(event) =>
                editor.chain().focus().setColor(event.target.value).run()
              }
              value={editor.getAttributes("textStyle").color}
              data-testid="setColor"
            />
          </label>
          <label className="texthightlight_group">
            <svg
              style={{ width: "calc(100% - 15px)" }}
              xmlns="http://www.w3.org/2000/svg"
              width="362"
              height="404"
              viewBox="0 0 362 404"
              fill="none"
            >
              <path
                d="M116.979 325.981H244.102L267.093 403.894H361.261L230.81 0.972168H130.271L0.111328 403.894H94.277L116.979 325.981ZM179.578 110.05H181.23L223.33 252.52H137.755L179.578 110.05Z"
                fill="black"
              />
            </svg>
            <input
              type="color"
              onInput={(event) =>
                editor
                  .chain()
                  .focus()
                  .toggleHighlight({ color: event.target.value })
                  .run()
              }
              value={editor.getAttributes("textHighlight").color}
            />
          </label>
          {!editor.isActive("link") ? (
            <button
              onClick={setLink}
              className={editor.isActive("link") ? "is-active" : ""}
            >
              Set link
            </button>
          ) : (
            <button
              onClick={() => editor.chain().focus().unsetLink().run()}
              disabled={!editor.isActive("link")}
            >
              Unset link
            </button>
          )}
          {/* {editor.isActive("highlight") && (
              <svg
                onClick={() => editor.chain().focus().unsetHighlight().run()}
                width="20"
                height="20"
                x="0"
                y="0"
                viewBox="0 0 320.591 320.591"
              >
                <g>
                  <path
                    d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z"
                    fill="#000000"
                    opacity="1"
                    class="hovered-path"
                  ></path>
                  <path
                    d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z"
                    fill="#000000"
                    opacity="1"
                    class="hovered-path"
                  ></path>
                </g>
              </svg>
            )} */}
          {/* <button
            onClick={() => editor.chain().focus().toggleCode().run()}
            className={editor.isActive("code") ? "is-active" : ""}
          >
            Toggle code
          </button>
         
          <button
            onClick={() =>
              editor.chain().focus().splitListItem("listItem").run()
            }
            disabled={!editor.can().splitListItem("listItem")}
          >
            Split list item
          </button>
          <button
            onClick={() =>
              editor.chain().focus().sinkListItem("listItem").run()
            }
            disabled={!editor.can().sinkListItem("listItem")}
          >
            Sink list item
          </button>
          <button
            onClick={() =>
              editor.chain().focus().liftListItem("listItem").run()
            }
            disabled={!editor.can().liftListItem("listItem")}
          >
            Lift list item
          </button>
          <button
            onClick={() => editor.chain().focus().toggleHighlight().run()}
            className={editor.isActive("highlight") ? "is-active" : ""}
          >
            Toggle highlight
          </button>
          <button
            onClick={() =>
              editor.chain().focus().toggleHighlight({ color: "#ffc078" }).run()
            }
            className={
              editor.isActive("highlight", { color: "#ffc078" })
                ? "is-active"
                : ""
            }
          >
            Orange
          </button>
          <button
            onClick={() =>
              editor.chain().focus().toggleHighlight({ color: "#8ce99a" }).run()
            }
            className={
              editor.isActive("highlight", { color: "#8ce99a" })
                ? "is-active"
                : ""
            }
          >
            Green
          </button>
          <button
            onClick={() =>
              editor.chain().focus().toggleHighlight({ color: "#74c0fc" }).run()
            }
            className={
              editor.isActive("highlight", { color: "#74c0fc" })
                ? "is-active"
                : ""
            }
            >
            Blue
          </button>
          <button
            onClick={() =>
              editor.chain().focus().toggleHighlight({ color: "#b197fc" }).run()
            }
            className={
              editor.isActive("highlight", { color: "#b197fc" })
                ? "is-active"
                : ""
            }
          >
          Purple
          </button>
          <button
            onClick={() =>
              editor.chain().focus().toggleHighlight({ color: "red" }).run()
            }
            className={
              editor.isActive("highlight", { color: "red" }) ? "is-active" : ""
            }
          >
            Red ('red')
          </button>
          <button
            onClick={() =>
              editor.chain().focus().toggleHighlight({ color: "#ffa8a8" }).run()
            }
            className={
              editor.isActive("highlight", { color: "#ffa8a8" })
                ? "is-active"
                : ""
            }
          >
            Red (#ffa8a8)
          </button>
          <button
            onClick={() => editor.chain().focus().unsetHighlight().run()}
            disabled={!editor.isActive("highlight")}
          >
            Unset highlight
          </button>

          <input
            type="color"
            onInput={(event) =>
              editor.chain().focus().setColor(event.target.value).run()
            }
            value={editor.getAttributes("textStyle").color}
            data-testid="setColor"
          />
          <button
            onClick={() => editor.chain().focus().setColor("#958DF1").run()}
            className={
              editor.isActive("textStyle", { color: "#958DF1" })
                ? "is-active"
                : ""
                }
            data-testid="setPurple"
          >
            Purple
            </button>
          <button
            onClick={() => editor.chain().focus().setColor("#F98181").run()}
            className={
              editor.isActive("textStyle", { color: "#F98181" })
                ? "is-active"
                : ""
            }
            data-testid="setRed"
          >
            Red
          </button>
          <button
            onClick={() => editor.chain().focus().setColor("#FBBC88").run()}
            className={
              editor.isActive("textStyle", { color: "#FBBC88" })
                ? "is-active"
                : ""
            }
            data-testid="setOrange"
          >
            Orange
          </button>
          <button
            onClick={() => editor.chain().focus().setColor("#FAF594").run()}
            className={
              editor.isActive("textStyle", { color: "#FAF594" })
                ? "is-active"
                : ""
            }
            data-testid="setYellow"
          >
            Yellow
          </button>
          <button
            onClick={() => editor.chain().focus().setColor("#70CFF8").run()}
            className={
              editor.isActive("textStyle", { color: "#70CFF8" })
                ? "is-active"
                : ""
            }
            data-testid="setBlue"
          >
            Blue
          </button>
          <button
            onClick={() => editor.chain().focus().setColor("#94FADB").run()}
            className={
              editor.isActive("textStyle", { color: "#94FADB" })
                ? "is-active"
                : ""
            }
            data-testid="setTeal"
          >
            Teal
          </button>
          <button
            onClick={() => editor.chain().focus().setColor("#B9F18D").run()}
            className={
              editor.isActive("textStyle", { color: "#B9F18D" })
                ? "is-active"
                : ""
            }
            data-testid="setGreen"
          >
            Green
          </button>
          <button
            onClick={() => editor.chain().focus().unsetColor().run()}
            data-testid="unsetColor"
          >
            Unset color
          </button> */}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: [],
  liked: [],
  requestConnection: [],
  error: null,
  isLoading: false,
};
const reducerPromiseState = {
  pending: (state) => {
    state.isLoading = true;
    state.error = null;
  },
  fulfilled: (state) => {
    state.isLoading = false;
    state.error = null;
  },
  rejected: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },
};

export const getInvestorsWithFirms = createAsyncThunk(
  "investorsWithFirms/getInvestorsWithFirms",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        "https://accel-be.test-server.app/api/investment-firms/getFirmsWithInvestors",

        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);
export const setInvestorLiked = createAsyncThunk(
  "investorsWithFirms/setInvestorLiked",
  async ({ investorId, liked = false }, { getState, rejectWithValue }) => {
    try {
      const { investorsWithFirms } = getState();
      let updatedData = [...investorsWithFirms.liked];
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `https://accel-be.test-server.app/api/investors/${
          liked ? "un" : ""
        }like`,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          investorId: investorId,
        }),
      };

      const response = await axios.request(config);

      if (response.data.message) {
        if (liked) {
          updatedData = updatedData.filter((id) => id !== investorId);
        } else {
          updatedData = [...updatedData, investorId];
        }
        return updatedData;
      } else {
        return rejectWithValue("Unexpected response from server");
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const setInvestorRequestConnection = createAsyncThunk(
  "investorsWithFirms/setInvestorRequestConnection",
  async ({ investorId }, { getState, rejectWithValue }) => {
    try {
      const { investorsWithFirms } = getState();
      let updatedData = [...investorsWithFirms.requestConnection];
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `https://accel-be.test-server.app/api/introductions/request`,
        headers: {
          "Content-Type": "application/json",
        },
        data: JSON.stringify({
          investorId: investorId,
        }),
      };

      const response = await axios.request(config);

      if (response.data.message) {
        updatedData = [...updatedData, investorId];

        return updatedData;
      } else {
        return rejectWithValue("Unexpected response from server");
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export default createSlice({
  name: "investorsWithFirms",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    let builderMap = {
      getInvestorsWithFirms: {
        fulfilled: (state, action) => {
          state.isLoading = false;
          state.data = action.payload?.firmsWithInvestors || [];
          state.requestConnection = action.payload?.userIntroductions || [];
          state.liked = action.payload?.userLikes || [];
        },
      },
      setInvestorLiked: {
        fulfilled: (state, action) => {
          state.isLoading = false;
          state.liked = action.payload;
        },
      },
      setInvestorRequestConnection: {
        fulfilled: (state, action) => {
          state.isLoading = false;
          state.requestConnection = action.payload;
        },
      },
    };
    [
      "getInvestorsWithFirms",
      "setInvestorLiked",
      "setInvestorRequestConnection",
    ].forEach((func) => {
      return ["pending", "fulfilled", "rejected"].map((promiseState) => {
        builder.addCase(
          eval(func)[promiseState],
          builderMap?.[func]?.[promiseState] ||
            reducerPromiseState[promiseState]
        );
      });
    });
  },
}).reducer;

import React, { useEffect, useState } from "react";
import RTE from "../../component/RTE/RTE";
import { useSelector } from "react-redux";
import { RTE_Remasterd } from "../../component/RTE/RTE_Remasterd";
import axios from "axios";

const MailMerge = () => {
  const store = useSelector((state) => state);
  const [is_loader, setis_loader] = useState(false);
  const [people, setpeople] = useState([]);
  const [email_sent_counter, setemail_sent_counter] = useState(0);
  const [search_people, setsearch_people] = useState("");
  const [preview_search_people, setpreview_search_people] = useState("");
  const [preview_people, setpreview_people] = useState([]);
  const [subject, setsubject] = useState([]);

  const [message_body, setmessage_body] = useState("");
  const [message, setmessage] = useState([]);
  const [suggestions, setsuggestions] = useState([]);
  const investorData = useSelector((state) => state.investors);
  const [preview, setpreview] = useState(false);

  useEffect(() => {
    if (people?.length > 0) {
      setpreview_people(people[0]);
      function getCommonNonEmptyKeys(investorArray) {
        if (investorArray.length === 0) return [];

        // Get the initial set of keys from the first investor object
        let commonKeys = Object.keys(investorArray[0]);

        investorArray.forEach((investor) => {
          commonKeys = commonKeys.filter((key) => {
            const value = investor[key];
            // Check if the value is non-empty
            return (
              value !== null &&
              value !== undefined &&
              value !== "" &&
              !(Array.isArray(value) && value.length === 0)
            );
          });
        });

        // Verify that each key in the final set is present in every investor
        return commonKeys.filter((key) =>
          investorArray.every((investor) => key in investor)
        );
      }
      setsuggestions(getCommonNonEmptyKeys(people));
    }
  }, [people]);
  const sendMail = () => {
    if (
      !(people.length > 0 && subject.length > 2 && message_body.length > 10)
    ) {
      return;
    }
    setis_loader(true);

    let data = {
      arr: people.map((el) => ({
        to: el.email,
        subject: subject,
        html: (() =>
          Object.keys(el).reduce(
            (acc, curr) => acc.replaceAll(`{${curr}}`, el[curr]),
            message_body
          ))(),
      })),
    };
    // console.log(data.arr);
    // return;

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://accel-be.test-server.app/api/send-email",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
      onUploadProgress: (progressEvent) => {
        // Calculate the percentage of completion
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        console.log(`Progress: ${percentCompleted}%`);
        // setis_loader(false);
        if (percentCompleted >= 100) {
          // setis_loader(false);
          // setemail_sent_counter(0);
          // setpeople([]);
          // setsubject("");
          localStorage.setItem("mailData", "<p></p>");
          window.location.reload();
        }
      },
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });

    // send mail function here
    // people.map((el, i) => {
    //   let data = JSON.stringify({
    //     to: el.email,
    //     subject: subject,
    //     html: (() =>
    //       Object.keys(el).reduce(
    //         (acc, curr) => acc.replaceAll(`{${curr}}`, el[curr]),
    //         message_body
    //       ))(),
    //   });

    //   let config = {
    //     method: "post",
    //     maxBodyLength: Infinity,
    //     url: "https://accel-be.test-server.app/api/send-email",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     data: data,
    //   };

    //   axios
    //     .request(config)
    //     .then((response) => {
    //       console.log(JSON.stringify(response.data));
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });

    //   // setTimeout(() => {
    //   //   setemail_sent_counter((prev) => {
    //   //     if (people.length == prev + 1) {
    //   //       setis_loader(false);
    //   //       setemail_sent_counter(0);
    //   //       setpeople([]);
    //   //       setsubject("");
    //   //       localStorage.setItem("mailData", "<p></p>");
    //   //     }
    //   //     return prev + 1;
    //   //   });
    //   // }, 1000 * i);
    // });
  };
  // if (!is_loader) {
  //   return <div class="loading">loading</div>;
  // }

  return (
    <div className="mail_merge">
      {is_loader ? (
        <div className="loading">
          <div className="txt1">Sending email please wait</div>
        </div>
      ) : (
        <>
          <label className="send_to" htmlFor="people_input">
            <div className="send_to_txt">To:</div>
            <div className="send_to_main">
              {people?.map((el) => (
                <div
                  className={
                    "people_item" +
                    (preview_people.email == el.email
                      ? " preview_selected"
                      : "")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setpreview_people({ ...el });
                  }}
                >
                  <div
                    className="img"
                    style={{
                      backgroundImage: `url(${el["photo"]})`,
                    }}
                  ></div>
                  <div className="people_details">
                    <div className="email">{el["email"]}</div>
                  </div>
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      setsearch_people("");
                      setpeople((prev) =>
                        prev.filter((curr) => curr["email"] != el["email"])
                      );
                    }}
                    className="remove_btn"
                  >
                    <svg
                      width="20"
                      height="20"
                      x="0"
                      y="0"
                      viewBox="0 0 320.591 320.591"
                    >
                      <g>
                        <path
                          d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z"
                          fill="#000000"
                          opacity="1"
                          className="hovered-path"
                        ></path>
                        <path
                          d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z"
                          fill="#000000"
                          opacity="1"
                          className="hovered-path"
                        ></path>
                      </g>
                    </svg>
                  </div>
                </div>
              ))}
              {!investorData.isLoading ? (
                <label className="people_dropdown" htmlFor="people_selector">
                  <div
                    // onChange={(e) => {
                    //   setpeople(e.target.value);
                    // }}
                    className="add"
                  >
                    <input
                      type="text"
                      id="people_input"
                      value={search_people}
                      onChange={({ target: { value } }) => {
                        setsearch_people(value);
                      }}
                      autoComplete={false}
                    />

                    {/* <input type="radio" id="people_selector" /> */}
                    <div className="people_dropdown_body">
                      <div>
                        {investorData?.data
                          ?.filter((el) =>
                            [el["email"], el["name"]]
                              ?.join(",")
                              .includes(search_people)
                          )
                          .map((el) => {
                            let is_exists =
                              people.filter(
                                (curr) => curr["email"] == el["email"]
                              ).length > 0;
                            return (
                              <div
                                onClick={() => {
                                  setsearch_people("");
                                  if (is_exists) {
                                    setpeople((prev) =>
                                      prev.filter(
                                        (curr) => curr["email"] != el["email"]
                                      )
                                    );
                                  } else {
                                    setpeople((prev) => [...prev, el]);
                                  }
                                }}
                                className={
                                  is_exists
                                    ? "people_item selected"
                                    : "people_item"
                                }
                              >
                                <div
                                  className="img"
                                  style={{
                                    backgroundImage: `url(${el["photo"]})`,
                                  }}
                                ></div>
                                <div className="people_details">
                                  <div className="name">{el["name"]}</div>
                                  <div className="email">{el["email"]}</div>
                                </div>
                              </div>
                            );
                          })}
                        {/* <div className={"people_item add_person"}>
                          add person ?
                        </div> */}
                      </div>
                    </div>
                  </div>
                </label>
              ) : (
                "Fetching Investors..."
              )}
            </div>
            <div
              className="send_btn"
              active={String(
                people.length > 0 &&
                  subject.length > 2 &&
                  message_body.length > 10
              )}
              onClick={sendMail}
            >
              <svg
                width="20"
                height="20"
                x="0"
                y="0"
                viewBox="0 0 176.368 176.368"
              >
                <g>
                  <path
                    d="M175.865 12.489c2.469-7.408-4.578-14.456-11.986-11.987L6.48 52.969c-8.839 2.946-8.565 15.542.393 18.101l76.552 21.873 21.872 76.552c2.56 8.959 15.155 9.233 18.101.393zm-12.34 7.055-49.116 147.348-21.83-76.403zm-6.701-6.701L85.879 83.788 9.477 61.959z"
                    clipRule="evenodd"
                    fill="#000000"
                    opacity="1"
                  ></path>
                </g>
              </svg>
            </div>
          </label>
          <label className="subject" htmlFor="subject_input">
            <div className="subject_txt">Subject:</div>
            <input
              rows={1}
              type="text"
              id="subject_input"
              value={subject}
              onChange={(e) => {
                setsubject(e.target.value);
              }}
            />
          </label>
          <div className="rte_body">
            <RTE_Remasterd
              {...{
                preview_people,
                settxt: (txt) => setmessage_body(txt),
                formatting: true,
                suggestions: suggestions,
              }}
            />
            <div
              className="preview"
              style={
                preview
                  ? {
                      width: "100%",
                    }
                  : {
                      width: "0",
                    }
              }
            >
              <div
                className="preview_txt"
                onClick={() => setpreview((prev) => !prev)}
              >
                Preview
              </div>
              {preview && (
                <div className="preview_body">
                  <div
                    className="tiptap"
                    dangerouslySetInnerHTML={{
                      __html: (() =>
                        Object.keys(preview_people).reduce(
                          (acc, curr) =>
                            acc.replaceAll(`{${curr}}`, preview_people[curr]),
                          message_body
                        ))(),
                    }}
                  ></div>
                </div>
              )}
            </div>
          </div>
          {/* {people.length > 0 &&
            subject.length > 2 &&
            message_body.length > 10 && (
              <div className="send_btn" onClick={sendMail}>
                Send
              </div>
            )} */}
        </>
      )}
    </div>
  );
};

export default MailMerge;

//

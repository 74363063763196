import React from "react";
import Navbar from "../../component/Navigation/Navbar";
import { useLocation } from "react-router-dom";

const Dashboard = ({ children }) => {
  const location = useLocation();
  return (
    <div
      className="dashboard"
      location={location.pathname.slice(1).replaceAll("/", "")}
    >
      <Navbar />
      {children}
      {/* <DashboardType
        {
          ...{
            // search,
            // setsearch,
            // showing_filters,
            // current_filters,
            // setcurrent_filters,
            // list,
            // setlist,
          }
        }
      /> */}
    </div>
  );
};

export default Dashboard;

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { filters } from "../../utils/data";
import { current } from "@reduxjs/toolkit";

const SearchAndFilter = ({
  count,
  search,
  setsearch,
  showing_filters,
  current_filters,
  setcurrent_filters,
  filter_modal,
}) => {
  const { type } = useParams();
  const navigate = useNavigate();
  // const [filter_modal, setfilter_modal] = useState(true);

  useEffect(() => {
    // window.onclick = () => {
    //   setfilter_modal(false);
    // };
  }, []);
  return (
    <div className="search_and_filter">
      <label htmlFor="search-input" className="search">
        <div className="sercah_icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            id="icon-import"
            viewBox="0 0 24 24"
            height="20"
            fill="none"
          >
            <link
              xmlns=""
              type="text/css"
              rel="stylesheet"
              id="dark-mode-custom-link"
            />
            <link
              xmlns=""
              type="text/css"
              rel="stylesheet"
              id="dark-mode-general-link"
            />
            <style
              xmlns=""
              lang="en"
              type="text/css"
              id="dark-mode-custom-style"
            />
            <style
              xmlns=""
              lang="en"
              type="text/css"
              id="dark-mode-native-style"
            />
            <style
              xmlns=""
              lang="en"
              type="text/css"
              id="dark-mode-native-sheet"
            />
            <path
              d="M15 14.899C16.2372 13.6364 17 11.9073 17 10C17 6.13401 13.866 3 10 3C6.13401 3 3 6.13401 3 10C3 13.866 6.13401 17 10 17C11.9587 17 13.7295 16.1955 15 14.899ZM15 14.899L19.5 19.5"
              stroke="var(--stroke-color, black)"
              strokeWidth="var(--strokeWidth, 1.5)"
              vectorEffect="non-scaling-stroke"
              strokeLinecap="square"
            />
          </svg>
        </div>
        <input
          placeholder="Search"
          type="text"
          value={search}
          onChange={({ target: { value } }) => setsearch(value)}
          id="search-input"
        />

        <div
          className="clear"
          is_hidden={(!(search.length > 0)).toString()}
          onClick={() => setsearch("")}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            id="icon-import"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
          >
            <link
              xmlns=""
              type="text/css"
              rel="stylesheet"
              id="dark-mode-custom-link"
            />
            <link
              xmlns=""
              type="text/css"
              rel="stylesheet"
              id="dark-mode-general-link"
            />
            <style
              xmlns=""
              lang="en"
              type="text/css"
              id="dark-mode-custom-style"
            />
            <style
              xmlns=""
              lang="en"
              type="text/css"
              id="dark-mode-native-style"
            />
            <style
              xmlns=""
              lang="en"
              type="text/css"
              id="dark-mode-native-sheet"
            />
            <path
              d="M5 5L19 19M19 5L5 19"
              stroke="var(--stroke-color, black)"
              strokeWidth="var(--strokeWidth, 1.5)"
              vectorEffect="non-scaling-stroke"
            />
          </svg>
        </div>
      </label>
      {/* <div
        className="filter"
        onClick={(e) => {
          e.stopPropagation();
          setfilter_modal((prev) => !prev);
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          version="1.1"
          xlink="http://www.w3.org/1999/xlink"
          width="20"
          height="20"
          x="0"
          y="0"
          viewBox="0 0 20 20"
          space="preserve"
        >
          <g>
            <g fill="#000">
              <path
                d="M0 5a1 1 0 0 1 1-1h18a1 1 0 1 1 0 2H1a1 1 0 0 1-1-1zM3 10a1 1 0 0 1 1-1h12a1 1 0 1 1 0 2H4a1 1 0 0 1-1-1zM8 14a1 1 0 1 0 0 2h4a1 1 0 1 0 0-2z"
                fill="var(--stroke-color, black)"
                opacity="1"
              ></path>
            </g>
          </g>
        </svg>
      </div> */}
      {filter_modal ? (
        <div className="filter_group" onClick={(e) => e.stopPropagation()}>
          {showing_filters.map(({ title, children, count }) => (
            <div className="filter_options">
              <input
                type="checkbox"
                name="global_filter"
                id={title}
                onBlur={(e) => {
                  e.target.checked = false;
                }}
              />
              <label htmlFor={title} className="title">
                {title}

                <div
                  className="count"
                  count={current_filters?.[title]?.length || 0}
                >
                  {current_filters[title].length}
                </div>
                {/* <div>count-{count}</div> */}

                <div className="arrow">
                  <svg
                    width="512"
                    height="512"
                    x="0"
                    y="0"
                    viewBox="0 0 451.847 451.847"
                  >
                    <g>
                      <path
                        d="M225.923 354.706c-8.098 0-16.195-3.092-22.369-9.263L9.27 151.157c-12.359-12.359-12.359-32.397 0-44.751 12.354-12.354 32.388-12.354 44.748 0l171.905 171.915 171.906-171.909c12.359-12.354 32.391-12.354 44.744 0 12.365 12.354 12.365 32.392 0 44.751L248.292 345.449c-6.177 6.172-14.274 9.257-22.369 9.257z"
                        fill="#000000"
                        opacity="1"
                        data-original="#000000"
                      ></path>
                    </g>
                  </svg>
                </div>
              </label>
              <div className="body">
                <div className="body_main">
                  {children.map((option) =>
                    option ? (
                      <div
                        className="options"
                        onClick={() => {
                          setcurrent_filters((prev) => ({
                            ...prev,
                            [title]: prev[title].includes(option)
                              ? prev[title].filter((el) => el != option)
                              : [...prev[title], option],
                          }));
                        }}
                      >
                        {option}
                        <div
                          className="checkbox"
                          is_checked={current_filters[title]
                            .includes(option)
                            .toString()}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="300"
                            height="218"
                            viewBox="0 0 300 218"
                            fill="none"
                          >
                            <path
                              d="M292.935 6.59995C284.401 -1.93338 271.601 -1.93338 263.068 6.59995L103.068 166.6L36.9346 100.467C28.4013 91.9333 15.6013 91.9333 7.06797 100.467C-1.46536 109 -1.46536 121.8 7.06797 130.333L88.1346 211.4C92.4013 215.667 96.668 217.8 103.068 217.8C109.468 217.8 113.735 215.667 118.001 211.4L292.935 36.4666C301.468 27.9333 301.468 15.1333 292.935 6.59995Z"
                              fill="white"
                            />
                          </svg>
                        </div>
                      </div>
                    ) : (
                      ""
                    )
                  )}
                </div>
              </div>
            </div>
          ))}
          {Object.keys(current_filters).some(
            (el) => current_filters[el].length > 0
          ) ? (
            <div
              className="filter_options"
              onClick={() => {
                setcurrent_filters((prev) =>
                  Object.keys(prev).reduce(
                    (acc, curr) => ({ ...acc, [curr]: [] }),
                    {}
                  )
                );
              }}
            >
              <div className="title">Clear All</div>
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default SearchAndFilter;
